import React, { Component } from "react";

class Map extends React.Component{

    constructor(props) {
        super(props);

    }

    componentDidMount() {
    }

    render() {

        return (
            <div>
                MAP
            </div>
        );
    }
}

export default Map;